import { FreestylePlayController } from './index.js';
import { cleanRhythmNoteByNote } from './rhythm.js';
import { findNextTick, updateCursorUI } from './commander.js';
export function resolveResultsToColor(
  results_,
  finalResults,
  playDataLoader,
  musicScore
) {
  const finalColorNotes = new Map();

  let i = 0;
  while (i < finalResults.length) {
    if (finalResults.userTime >= results_[0].userTime) {
      // resultsNoteStatus_ = {};
      break;
    }
    i++;
  }

  const discardResults = finalResults.slice(i);
  finalResults = finalResults.slice(0, i).concat(results_);
  FreestylePlayController.results = finalResults;
  // realtimeResults_.push(results_);
  // Redraw notes on wrong branch, 对之前的修正
  for (const discardResult of discardResults) {
    if (!discardResult.scoreNote) {
      continue;
    }
    // 拿到需要操作的 id
    const noteId = discardResult.scoreNote.id;

    // 准备音准染色
    let color = ColorEnum.black;
    if (scoreColors.has(noteId)) {
      // rollback color
      const colors = scoreColors.get(noteId);
      colors.pop();
      if (colors.length > 0) {
        color = colors[colors.length - 1];
      }
    }

    const noteIds = playDataLoader.rawMidi.getOverLapNoteIds(noteId);
    noteIds.forEach((id) => {
      const note = musicScore.getNoteById(id);
      if (note) {
        finalColorNotes.set(note, { color, result: discardResult });
      }
    });
  }

  // draw notes on new branch, 往下弹行为

  for (const result of results_) {
    if (!result.scoreNote) {
      continue;
    }
    // new branch 音符变色
    let color = ColorEnum.black;
    if (result.pitch === PitchStatusEnum.right) {
      color = ColorEnum.green;
    } else if (result.pitch === PitchStatusEnum.wrong) {
      color = ColorEnum.red;
    } else if (result.pitch === PitchStatusEnum.miss) {
      color = ColorEnum.missingGrey;
    }
    const noteId = result.scoreNote.id;
    if (!scoreColors.has(noteId)) {
      scoreColors.set(noteId, []);
    }
    scoreColors.get(noteId).push(color);

    const noteIds = playDataLoader.rawMidi.getOverLapNoteIds(noteId);
    noteIds.forEach((id) => {
      const note = musicScore.getNoteById(id);
      if (note) {
        // resultsNoteStatus_[id] = result.pitch;
        finalColorNotes.set(note, { color, result });
      }
    });
  }
  return finalColorNotes;
}

export function resolveResultsToRhythm(
  results_,
  noteTickNoPause_,
  musicScore,
  id2MidiJsonNote
) {
  // 我们要根据 result 和 note 来判定是否应该是 early, late 还是 pause
  const finalNotesNeedRhythm = new Map();
  for (let i = 0; i < results_.length; i++) {
    const item = results_[i];
    const note = item[0];
    const result = item[1];
    if (!result.scoreNote.grace && result.rhythmAbsolute !== null) {
      const note = musicScore.getNoteById(result.scoreNote.id);

      if (
        result.rhythmAbsolute >=
          SeaAlgorithmRhythmThresholdEnum.RHYTHM_ABSOLUTE &&
        result.rhythm >= SeaAlgorithmRhythmThresholdEnum.RHYTHM_RATE &&
        result.rhythmAbsolute <= SeaAlgorithmRhythmThresholdEnum.PAUSE
      ) {
        // late
        finalNotesNeedRhythm.set(note, 'late');
      } else if (
        result.rhythmAbsolute <=
          -SeaAlgorithmRhythmThresholdEnum.RHYTHM_ABSOLUTE &&
        1 / result.rhythm >= SeaAlgorithmRhythmThresholdEnum.RHYTHM_RATE
      ) {
        // Early
        finalNotesNeedRhythm.set(note, 'early');
      } else if (
        result.rhythmAbsolute > SeaAlgorithmRhythmThresholdEnum.PAUSE
      ) {
        // pause
        const tick = id2MidiJsonNote[note.id].tick;
        if (!noteTickNoPause_[tick]) {
          finalNotesNeedRhythm.set(note, 'pause');
        }
      } else {
        finalNotesNeedRhythm.delete(note);
        cleanRhythmNoteByNote(note);
      }
    } else {
      finalNotesNeedRhythm.delete(note);
      cleanRhythmNoteByNote(note);
    }
  }
  return finalNotesNeedRhythm;
}

export function resolveResultsToCommanderCKL(results_) {
  let maxTick = null;
  for (let i = results_.length - 1; i >= 0; i--) {
    /**
     * 多余的音不判断
     */
    if (results_[i].pitch === 'redundant') {
      continue;
    }
    const item = results_[i];
    if (maxTick === null) {
      maxTick = results_[i].scoreTick;
    }
    if (maxTick === item.scoreTick && item.pitch !== PitchStatusEnum.right) {
      return false;
    } else {
      return true;
    }
  }
}

export function resolveTick(
  tick_,
  isLastEventAllCorrect,
  tick2MidiNotes,
  musicScore,
  engineCursor
) {
  let targetTick = tick_;
  // 因为如果 tick_ 是endTick 那么就会超出弹奏范围

  if (isLastEventAllCorrect) {
    // 说明是全对 那么就找到下一个 event
    targetTick = findNextTick(tick_, tick2MidiNotes);
  }
  const noteId = tick2MidiNotes[targetTick][0].score.noteId;
  const note = musicScore.getNoteById(noteId);
  if (note) {
    updateCursorUI(note, engineCursor);
  }
  isLastEventAllCorrect = false;
}

let currentSystem = -1;
function _isLastSystemId(systemId) {
  if (currentSystem === systemId + 1) {
    return true;
  }
  return false;
}

export function resolveScroll(systemId_, position_, musicScore, engineCursor) {
  if (_isLastSystemId(systemId_) && position_ === 'middle') {
    return;
  }
  currentSystem = systemId_;
  let systemIdTemp = 0;
  if (position_ === 'middle') {
    if (systemId_ >= 1) {
      systemIdTemp = systemId_ - 1;
    }
  } else {
    systemIdTemp = systemId_;
  }
  const system = musicScore.getSystemById(systemIdTemp);
  if (system) {
    const y = system.position[1] + system._parent.position[1];
    const x = system.position[0] + system._parent.position[0];
    musicScore.scrollOffset(x, y - engineCursor._topMargin);
  }
}

export const ColorEnum = {
  green: 'rgb(38, 183, 122)',
  unTouchedGrey: '#CCCCCC',
  missingGrey: '#9A9A9A',
  red: 'red',
  black: 'black',
  redOpacity: 'rgba(255, 0, 0, 0.5)',
  wrongSection: 'rgba(224, 32, 32, 0.1)',
  correctSection: 'rgba(48, 202, 136, 0.1)',
};

export const PitchStatusEnum = {
  right: 'right',
  miss: 'miss',
  wrong: 'wrong',
};

export const SeaAlgorithmRhythmThresholdEnum = {
  // 快慢 阈值 ms
  RHYTHM_ABSOLUTE: 75,
  // 快慢 阈值 比例
  RHYTHM_RATE: 1.25,
  // 卡顿 阈值 ms
  PAUSE: 500,
};

let scoreColors = new Map();
