import { ColorEnum } from './resolve';

export function cleanRhythmNoteByNote(note_) {
  const target = document.getElementById(`cloneRhythmNote-${note_.id}`);
  if (target) {
    target.remove();
    const isTextSVG =
      target.getAttribute('rhythmTag') === 'pause' ? true : false;
    if (isTextSVG) {
      // 说明这就是一个文字做成的 svg 就是 UI 上的 卡
      const dom = document.getElementById(`cloneRhythmNote-${note_.id}-bg`);
      if (dom) {
        dom.remove();
      }
    }
  }
}

export function rhythmNote(note_, rhythmStyle_, id2MidiJsonNote, renderEngine) {
  const xOffset = 2;
  switch (rhythmStyle_) {
    // 弹快了或者慢了
    case 'early':
    case 'late':
      {
        let targetRhythmTag = null;
        const tempDom = document.getElementById(`cloneRhythmNote-${note_.id}`);
        if (tempDom) {
          targetRhythmTag = tempDom.getAttribute('rhythmTag');
        }

        if (targetRhythmTag === rhythmStyle_) {
          // 如果已经存在了,并且跟当前的比一样 就算了
          return;
        }
        // 跟之前不一样的 rhythm
        cleanRhythmNoteByNote(note_);
        const cloneNote = note_.clone();
        cloneNote._view._dom.id = `cloneRhythmNote-${note_.id}`;
        cloneNote._view._dom.setAttribute('rhythmTag', `${rhythmStyle_}`);
        cloneNote._view._dom.setAttribute(
          'class',
          `rhythm rhythm-duplicate-${note_.id}`
        );
        cloneNote.color = ColorEnum.redOpacity;
        if (rhythmStyle_ === 'early') {
          cloneNote.position = [
            cloneNote.position[0] - xOffset,
            cloneNote.position[1],
          ];
        } else {
          cloneNote.position = [
            cloneNote.position[0] + xOffset,
            cloneNote.position[1],
          ];
        }
      }

      break;
    // 卡顿
    default: {
      const tick = id2MidiJsonNote[note_.id].tick;
      // 如果算法刷新, 之前已经渲染了一遍, 那么因为先渲染绿色的所以 judge
      const pauseNote = document.getElementsByClassName(`note-tick-${tick}`);

      // 说明之前就已在 有 pause 的卡字存在了, 直接返回
      if (pauseNote.length) {
        return;
      }
      // 跟之前不一样的 rhythm
      cleanRhythmNoteByNote(note_);
      const x = note_.position[0];
      const text = renderEngine.render.MusicScoreTextRender.create(
        note_.system,
        {}
      );
      note_.system.addChild(text, 0);
      text.view.id = `cloneRhythmNote-${note_.id}`;
      text.view.setAttribute('rhythmTag', `${rhythmStyle_}`);
      text.view.setAttribute('class', `rhythm note-tick-${tick}`);
      text.position = [x, -3];
      text.bgColor = 'rgba(254, 92, 90, 1)';
      text.borderHorizontalPadding = 3;
      text.borderVerticalPadding = 1.6;
      text.borderRx = '50%';
      text.borderRy = '50%';
      text.text = '卡';
      text.color = 'rgb(255, 255, 255)';
      text.fontSize = 8;
      text._borderRect.view.id = `cloneRhythmNote-${note_.id}-bg`;
      text._borderRect.view.setAttribute('class', `rhythm note-tick-${tick}`);

      break;
    }
  }
}
export function cleanRhythmNoteDuplicate(note_) {
  const notes = document.getElementsByClassName(`rhythm-duplicate-${note_.id}`);
  while (notes.length > 1) {
    notes[0].remove();
  }
}
