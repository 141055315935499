import bus from './bus';
import { colorNote } from './color';
import {
  resolveResultsToColor,
  ColorEnum,
  resolveResultsToRhythm,
  resolveResultsToCommanderCKL,
  resolveTick,
  resolveScroll,
} from './resolve';
import { cleanRhythmNoteByNote, rhythmNote } from './rhythm';
import { MusicScoreCursor } from '@xiaoyezi/musicscore-engine';

export function startAlgorithm(
  resultsList_,
  tickScrollArray_,
  engineDataLoader,
  musicScore,
  tieMap,
  tick2MidiNotes,
  id2MidiJsonNote,
  renderEngine
) {
  _setupSeaAlgorithmEvents(
    engineDataLoader,
    musicScore,
    tieMap,
    tick2MidiNotes,
    id2MidiJsonNote,
    renderEngine
  );
  _emitProcessing(resultsList_, tickScrollArray_);
}

export function endAlgorithm() {
  clearInterval(FreestylePlayController.loop);
}

function _emitProcessing(resultsList_, tickScrollArray_) {
  const startTimeStamp = performance.now();
  let partUploadTime = resultsList_[0][0].partUploadTime;
  let TickTimes = Object.keys(tickScrollArray_[1]);
  let TickNotes = Object.values(tickScrollArray_[1]);
  let scrollTimes = Object.keys(tickScrollArray_[2]);
  let scrollSystems = Object.values(tickScrollArray_[2]);
  let isPad = tickScrollArray_[0];

  FreestylePlayController.loop = setInterval(() => {
    let currentTime = performance.now();
    let duration = currentTime - startTimeStamp;
    if (duration >= partUploadTime - 180) {
      bus.emitSync('results', resultsList_[0]);
      resultsList_.shift();
      if (resultsList_.length <= 0) {
        bus.off('results');
      }
      if (resultsList_.length) {
        partUploadTime = resultsList_[0][0].partUploadTime;
      }
    }

    if (duration >= parseInt(TickTimes[0])) {
      bus.emitSync('tick', parseInt(TickNotes[0]));
      TickTimes.shift();
      TickNotes.shift();
      if (TickTimes.length <= 0) {
        bus.off('tick');
      }
    }

    if (duration >= parseInt(scrollTimes[0])) {
      bus.emitSync('scroll', [parseInt(scrollSystems[0]), isPad]);
      scrollTimes.shift();
      scrollSystems.shift();
      if (scrollTimes.length <= 0) {
        bus.off('scroll');
      }
    }

    if (
      resultsList_.length <= 0 &&
      TickTimes.length <= 0 &&
      scrollTimes.length <= 0
    ) {
      endAlgorithm();
    }
  }, 10);
}

function _setupSeaAlgorithmEvents(
  engineDataLoader,
  musicScore,
  tieMap,
  tick2MidiNotes,
  id2MidiJsonNote,
  renderEngine
) {
  let isLastEventAllCorrect = null;
  let engineCursor = new MusicScoreCursor(musicScore);
  bus.on('results', (results) => {
    const notesNeedColor = resolveResultsToColor(
      results,
      FreestylePlayController.results,
      engineDataLoader,
      musicScore
    );
    //通过颜色先区分出哪一些是需要去节奏判定
    const resultNeedJudgeRhythm = [];
    // 这个用来记录哪一些 tick 上的音符已经被渲染成了非绿色音符, 如过该 tick 已经有其他颜色的了, 就不能渲染 pause
    const noteTickNoPause = {};
    notesNeedColor.forEach((value, note) => {
      // 音符染色
      colorNote(value.color, note, tieMap, musicScore);
      // 因为在之后标记绿色的result, 才能去标记节奏判定
      // 没有标记的绿色 result 是需要清除掉对应 rhythm 标记
      if (value.color === ColorEnum.green) {
        resultNeedJudgeRhythm.push([note, value.result]);
      } else {
        // 清除 所有 rhythm 标记
        cleanRhythmNoteByNote(note);
        // 记录 不能渲染 pause 的 tick
        noteTickNoPause[id2MidiJsonNote[note.id].tick] = true;
      }
    });

    // 把确定需要去判断节奏的音符给判断
    const notesNeedRhythm = resolveResultsToRhythm(
      resultNeedJudgeRhythm,
      noteTickNoPause,
      musicScore,
      id2MidiJsonNote
    );

    // 最终渲染节奏错误
    notesNeedRhythm.forEach((value, note) => {
      rhythmNote(note, value, id2MidiJsonNote, renderEngine);
    });

    // 记录我们的 result 中的最后一组 event是否是全对
    isLastEventAllCorrect = resolveResultsToCommanderCKL(results);
  });

  bus.on('tick', (tick) => {
    resolveTick(
      tick,
      isLastEventAllCorrect,
      tick2MidiNotes,
      musicScore,
      engineCursor
    );
  });

  bus.on('scroll', (param) => {
    // 记录滚谱指令

    if (param[1]) {
      resolveScroll(param[0], 'middle', musicScore, engineCursor);
    } else {
      resolveScroll(param[0], 'top', musicScore, engineCursor);
    }
  });
}

export let FreestylePlayController = {
  results: [],
  loop: 0,
};
