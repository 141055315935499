import BtnLock from '@/baseEngine/btnLock';
import { startAlgorithm, endAlgorithm } from './core/index.js';
import { RenderEngine } from '@xiaoyezi/musicscore-engine';
export default {
  data: () => ({
    speedCn: 1,
    audioPlayer: null,
    playSeekTime: 0,
    audioControler: null,
    progress: null,
    ready: false, // 首次等musicScore待客户端加载音频
    firstLoadMidi: false,
    loadSuccess: true,
    playComplete: false,
    id2MidiJsonNote: {},
    tiesMap: {},
    tick2MidiNotes: {},
  }),
  methods: {
    async initAudioPlay() {
      this.audioPlayer = this.$refs.audio;
      console.log('lzs->initAudioPlay');

      let notes = this.scoreData.midi.notes[0]
        .reduce((acc, cur) => acc.concat(cur), [])
        .sort((a, b) => a.tick - b.tick);

      const repeatDisabledArr = []; // 被禁掉的反复点片段
      if (
        this.scoreData.config &&
        this.scoreData.config.repeats &&
        this.scoreData.config.repeats.length > 0
      ) {
        this.scoreData.config.repeats.forEach((repeat) => {
          if (!repeat.enable && repeat.segments && repeat.segments.length > 0) {
            repeatDisabledArr.push(repeat.segments);
          }
        });
      }
      // 过滤掉被禁掉反复点tick的音符
      if (repeatDisabledArr.length > 0) {
        notes = notes.filter((note) => {
          let flag = true;
          const tick = note.tick;
          for (let i = 0; i < repeatDisabledArr.length; i++) {
            const item = repeatDisabledArr[i];
            // 被禁掉的反复，左闭右开
            if (tick >= item[0] && tick < item[1]) {
              flag = false;
              break;
            }
          }
          if (flag) {
            return note;
          }
        });
      }

      // 遍历 midi json 里面的 note
      const id2MidiJsonNote = {};
      const tick2MidiNotes = {};
      const hand = this.noteResultsData.hand;

      notes.forEach((note) => {
        /**
         * 将所有score notes以id为下标生成对象，并去掉反复, 保留tick最小的那个
         **/
        if (!id2MidiJsonNote[note.score.noteId]) {
          id2MidiJsonNote[note.score.noteId] = note;
        } else if (id2MidiJsonNote[note.score.noteId].tick > note.tick) {
          id2MidiJsonNote[note.score.noteId] = note;
        }

        // 处理所有 tie 相关的
        const mainNoteTies = [];
        this._resolveTie(note, id2MidiJsonNote, mainNoteTies);

        // 处理 tick2MidiNotes
        // 将所有score notes以tick为下标生成对象, 忽略休止符
        if (note.type !== 'rest') {
          if (hand === 1) {
            if (note.hand === 0) {
              if (!tick2MidiNotes[note.tick]) {
                tick2MidiNotes[note.tick] = [];
              }
              tick2MidiNotes[note.tick].push(note);
            }
          } else if (hand === 0) {
            if (!tick2MidiNotes[note.tick]) {
              tick2MidiNotes[note.tick] = [];
            }
            tick2MidiNotes[note.tick].push(note);
          } else {
            if (note.hand === 1) {
              if (!tick2MidiNotes[note.tick]) {
                tick2MidiNotes[note.tick] = [];
              }
              tick2MidiNotes[note.tick].push(note);
            }
          }
        }
      });
      this.id2MidiJsonNote = id2MidiJsonNote;
      this.tick2MidiNotes = tick2MidiNotes;

      this.audioPlayer.onended = () => {
        this.stopAudition();
      };
    },

    // 获取试听乐句起始结束noteId
    loadAuditionMidi() {
      this.firstLoadMidi = true;
      // 提升endtick 需要修正tick，减去时值

      // TODO implement seek
      // this.audioControler.load()
      // TODO give a more accurate playSeekTime
      // this.playSeekTime = (this.audioControler.range && this.audioControler.range.start) || 0
    },

    // 试听开始播放
    startAudition() {
      if (!this.noteResultsData.audio_url) {
        console.error('没有音频文件');
        return;
      }

      if (this.listenPlaying || !this.loadFinish || !this.loadSuccess) {
        console.error('试听音频准备中，请稍候重试');
        return;
      }

      if (BtnLock.lock) {
        console.log('开始试听->操作过于频繁');
        return;
      }

      BtnLock.initLock(500);

      // if (!this.firstLoadMidi) this.loadAuditionMidi()

      // 更新各种状态
      this.listenPlaying = true;

      this.audioPlayer.rate = this.speedCn;
      // this.audioPlayer.seek(0)
      // 播放器开始播放
      setTimeout(() => {
        this.audioPlayer.play();
        // this._webAuditionIng();
        startAlgorithm(
          this.noteResultsData.realtime_results,
          this.noteResultsData.realtime_play,
          this.playDataLoader,
          this.musicScore,
          this.tiesMap,
          this.tick2MidiNotes,
          this.id2MidiJsonNote,
          RenderEngine
        );
      }, 200);
    },

    // 关闭试听
    stopAudition() {
      if (!this.listenPlaying) return;

      if (BtnLock.lock) {
        console.log('停止试听->操作过于频繁');
        return;
      }
      BtnLock.initLock(500);
      this.audioPlayer.pause();
      this.listenPlaying = false;
      endAlgorithm();
    },

    // PC 播放进行中, 递归进行
    _webAuditionIng() {
      if (this.listenPlaying) {
        if (this._playIndex < this._playEndIndex) {
          const currentNote = this._playNotes[this._playIndex];
          const nextNote = this._playNotes[this._playIndex + 1];
          const currentTime = this._player.value.currentTime();
          if (currentTime < nextNote.userTime) {
            const duration = (nextNote.userTime - currentTime) / this._speed;

            const note = this.musicScore.getNoteById(currentNote.noteId);
            note && this.cursor.update(note);

            this._playerTime = setTimeout(() => {
              clearTimeout(this._playerTime);
              // 解决定时期间试听关闭的情况
              if (this.listenPlaying) {
                this._playIndex += 1;
                this._webAuditionIng();
              }
            }, duration);
          } else {
            this._playIndex += 1;
            this._webAuditionIng();
          }
        } else {
          // 播放最后一个音符时，定位到音符，等时间结束，发送播放结束的事件
          const lastNote = this._playNotes[this._playIndex];
          const duration = this._tick2Time(lastNote.duration, this._musicTempo);

          const note = this.musicScore.getNoteById(lastNote.noteId);
          note && this.cursor.update(note);

          const timer = setTimeout(() => {
            clearTimeout(timer);
            this.listenPlaying = false;
            this.audioPlayer.pause();
          }, duration);
        }
      }
    },

    _resolveTie(note_, id2MidiJsonNote_, tieArray_) {
      if (note_.ties && note_.ties.length) {
        note_.ties.forEach((tieNote) => {
          this._setTies(tieNote.score.noteId, tieNote);
          !id2MidiJsonNote_[tieNote.score.noteId] &&
            (id2MidiJsonNote_[tieNote.score.noteId] = tieNote);
          tieArray_.push(tieNote.score.noteId);
          this._resolveTie(tieNote, id2MidiJsonNote_, tieArray_);
        });
      }
    },

    _setTies(noteId, note) {
      this.tiesMap[noteId] = note;
    },
  },
};
