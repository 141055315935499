export function findNextTick(tick_, tick2MidiNotes) {
  const ticks = Object.keys(tick2MidiNotes);
  const currentIndex = ticks.indexOf(tick_.toString());
  const nextIndex =
    currentIndex + 1 < ticks.length ? currentIndex + 1 : currentIndex;
  return parseInt(ticks[nextIndex]);
}

export function updateCursorUI(note, engineCursor) {
  engineCursor.update(note);
}
